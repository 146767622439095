<template>
  <div>
    <NavBar class="nav-bar" :bool="bool">
      <div slot="title">确认订单</div>
    </NavBar>
    <div class="layout-page">
      <!-- 地址 -->
      <div class="address-layout">
        <van-icon name="location-o" size="20" />
        <div class="shop-info">
          <div class="contact">
            <span>{{ shopInfo.shop_name }}</span>
            <span class="mobile">{{ shopInfo.shop_phone }}</span>
          </div>
          <div class="address">
            {{ shopInfo.city_name }}{{ shopInfo.address }}
          </div>
        </div>
      </div>
      <!-- 商品卡片 -->
      <div class="card-layout">
        <div v-for="(shopGoods, s_index) in goodsList" :key="s_index">
          <div class="card-header">
            <!-- <van-icon name="shop-o" color="#9b9b9b" /> -->
            <img src="~@/assets/common/store_icon@2x.png" class="shop-icon" />
            <div class="title">供应商：{{ shopGoods.shop_name }}</div>
          </div>
          <!-- 订单详情 -->
          <div
            class="order-details"
            v-for="(goods, g_index) in shopGoods.goods_list"
            :key="g_index"
          >
            <div class="img-box">
              <img class="commodity-img" :src="goods.goods_img" alt="" />
            </div>
            <div class="commodity-info">
              <div class="title-info">{{ goods.goods_name }}</div>
              <div class="specification">
                <span class="measure">{{ goods.item_name }}</span>
                <div v-if="orderType !== 3">
                  <div class="tag" v-if="goods.freight_money * 1 <= 0">
                    免运费
                  </div>
                  <div class="tag-freight" v-else>
                    运费：{{ twoNum(goods.freight_money) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="commodity-right">
              <span class="beans-num">{{ goods.spec_price }}公益豆</span>
              <div class="number-of">x{{ goods.number }}</div>
            </div>
          </div>
          <div class="operation">
            <van-cell-group :border="false">
              <van-field
                v-model="value"
                label="配送方式"
                readonly
                placeholder="到店自取"
              />
            </van-cell-group>
            <van-field
              v-model="message[s_index].msg"
              rows="2"
              autosize
              label="留言"
              type="text"
              placeholder="建议留言前先与商家沟通"
              show-word-limit
            />
          </div>
        </div>
      </div>
      <div class="beans-price">
        <div class="price">
          <span>商品总价</span>
          <span>{{ totalInfo.total_price }}公益豆</span>
        </div>
        <div class="price">
          <span>合计</span>
          <span style="color: #ed301d">
            {{ totalInfo.total_price }}公益豆
          </span>
        </div>
      </div>
      <div class="bottom-button">
        <span style="color: #aaaaaa">共一件，</span>
        <span>合计公益豆：</span>
        <span style="color: #ed301d">{{ totalInfo.total_price }}</span>
        <div class="button-box">
          <van-button
            round
            class="submit-button"
            color="linear-gradient(to right, #FE5876, #ED301D)"
            @click="createOrder"
          >
            提交订单
          </van-button>
        </div>
      </div>
    </div>
    <!--订单支付-->
    <PayShade
      v-if="isPay"
      :moneyInfo="moneyInfo"
      :orderId="orderId"
      :orderType="orderType"
      :showPay="isPay"
      @closeOverlay="closeOverlay"
    ></PayShade>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import { getShoppingGoods, getTotalPrice } from "@/api/shopping";
import { setOrderPay } from "@/api/order";
import { Toast } from "vant";
import { getAddressInfo, getMoneyInfo } from "@/api/member";
import { getShopDetails } from "@/api/shop";
import PayShade from "@/components/pay-shade/pay-shade";
import { createBenefitOrder } from "@/api/benefit";
export default {
  name: "commit-beans",
  components: {
    NavBar,
    PayShade,
  },
  data() {
    return {
      bool: this.$route.query.bool,
      orderType: 3,
      shoppingIds:
        this.$route.query.shoppingIds === undefined
          ? "0"
          : this.$route.query.shoppingIds,
      message: [],
      numberOf: 0,
      goodsList: [],
      totalInfo: { total_price: 0, total_postage: 0, total_expense: 0 },
      totalMoney: 0,
      purchaseMoney: "",
      addressInfo: { id: 0, province: 0, city: 0, area: 0 },
      shopInfo: { id: 0, shop_name: "" },
      shopId: 0,
      orderId: 0,
      moneyInfo: { purchase_money: 0, shop_money: 0 },
      showAddress: false,
      isPay: false,
      payType: 1,
      value: "",
      field: "",
      showOverlay: false,
      isShow: false, // 弹出层
    };
  },
  mounted() {
    this.getShoppingGoods();
    this.getAddressInfo();
    this.getMoneyInfo();
  },
  methods: {
    async getShoppingGoods() {
      //获取商品信息
      const ret = await getShoppingGoods({
        shopping_ids: this.shoppingIds,
        type: 3,
      });
      console.log(ret);
      if (ret.code * 1 === 1) {
        if (ret.data.length <= 0) {
          Toast.fail("商品信息异常");
          return false;
        }
        for (let i = 0; i < ret.data.length; ++i) {
          this.message.push({ msg: "" });
        }
        this.goodsList = ret.data;
        this.shopId = ret.data[0].shop_id;
      }
      this.getTotalPrice();
      this.getMyShopMoneyInfo();
    },
    async getTotalPrice() {
      //获取订单总价
      const ret = await getTotalPrice({
        shopping_ids: this.shoppingIds,
        province_id: this.addressInfo.province,
        city_id: this.addressInfo.city,
      });
      if (ret.code * 1 === 1) {
        this.totalInfo = ret.data;
        this.setTotalMoney();
      }
    },
    setTotalMoney() {
      this.totalMoney = this.totalInfo.total_price;
      //计算订单邮费
      this.goodsList.forEach((shop, shopKey) => {
        if (this.totalInfo.shop_list[shop.shop_id] !== undefined) {
          shop.goods_list.forEach((goods, goodsKey) => {
            if (
              this.totalInfo.shop_list[shop.shop_id].goods_postage[
                goods.spec_id
              ] !== undefined
            ) {
              goods.freight_money =
                this.totalInfo.shop_list[shop.shop_id].goods_postage[
                  goods.spec_id
                ];
            }
          });
        }
      });
    },
    setAddress() {
      //选择收货地址
      this.showAddress = true;
    },
    setAddressInfo(Ainfo) {
      // 设置收货地址
      this.addressInfo = Ainfo;
      this.showAddress = false;
    },
    closeOverlay() {
      // 关闭遮罩层自定义事件方法
      this.isPay = false;
    },
    delPass() {
      //密码清除
      let passNum = this.passStr.length;
      if (passNum > 1) {
        this.passStr = this.passStr.substr(0, passNum - 1);
      } else {
        this.passStr = "";
      }
    },
    setPass(key) {
      //设置密码
      if (this.passStr.length < 6) {
        this.passStr += key;
      }
      if (this.passStr.length == 6) {
        //调用支付
        this.setOrderPay();
      }
    },
    goToPaySuccess() {
      this.$route.push({
        name: "pay-success",
        query: { orderId: this.orderId, payMoney: this.payMoney },
      });
    },
    async setOrderPay() {
      //订单支付
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      const ret = await setOrderPay({
        balance_money: this.payMoney,
        order_id: this.orderId,
        pay_pass: this.passStr,
        pay_type: this.payType,
      });
      if (ret.code * 1 == 1) {
        if (ret.data.pay_status) {
          //跳转到支付成功页面
          this.goToPaySuccess();
        } else {
          //调用对应模块的支付
        }
      }
      this.isAjax = false;
    },
    async createOrder() {
      // 创建订单
      this.showPopup = true;

      if (this.shopInfo.id * 1 <= 0) {
        Toast.fail("提货店铺信息错误");
        return false;
      }
      if (this.shoppingIds == "0") {
        Toast.fail("商品信息异常");
        return false;
      }
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      const ret = await createBenefitOrder({
        shopping_ids: this.shoppingIds,
        claim_shop_id: this.shopInfo.id,
        note: this.message,
      });
      if (ret.code * 1 == 1) {
        this.orderId = ret.data.order_id;
        this.getOrderPayInfo();
      }
      this.isAjax = false;
    },
    async getOrderPayInfo() {
      //获取订单支付信息
      this.isPay = true;
      // const ret = await getOrderPayInfo({order_id: this.orderId});
      // if (ret.code * 1 === 1) {
      //   this.payInfo = ret.data;
      //   this.isPay = true;
      // }
    },
    async getMoneyInfo() {
      //获取会员资金信息
      const ret = await getMoneyInfo();
      if (ret.code * 1 === 1) {
        this.moneyInfo = ret.data;
      }
    },
    twoNum(num) {
      if (num === "") num = 0;
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    setExpenseMoney(expenseMoney) {
      this.expenseMoney = expenseMoney;
    },
    onFocus() {
      if (this.isFocus) {
        this.showKeyboard = true;
        this.show = true;
      }
    },
    async getAddressInfo() {
      const ret = await getAddressInfo();
      if (ret.code * 1 === 1) {
        this.addressInfo = ret.data;
        this.$emit("getAddressId", this.addressInfo);
      }
    },

    async getMyShopMoneyInfo() {
      //获取店铺进货金信息
      const ret = await getShopDetails({ shop_id: this.shopId });
      if (ret.code * 1 == 1) {
        this.shopInfo = ret.data;
      }
    },
    stockGold() {
      this.isShow = true;
    },
    setShow() {
      this.isShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
.layout-page {
  padding: 0 0 80px 0;
}
.nav-bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  border-bottom: 1px solid #ededed;
  background-color: #fff;
}
// 地址
.address-layout {
  display: flex;
  align-items: center;
  margin-top: 48px;
  padding: 12px 12px 10px 12px;
  background-color: #fff;
  border-radius: 0 0px 16px 16px;
  font-size: 16px;
  .shop-info {
    padding: 0 0 0 10px;
    width: 80%;
    .contact {
      font-size: 17px;
      font-weight: 500;
      .mobile {
        color: #000;
        font-weight: 400;
        padding-left: 10px;
      }
    }
    .address {
      font-size: 16px;
      color: #6f6f6f;
    }
  }
}
// 商品详情
.card-layout {
  padding: 12px;
  padding-bottom: 0;
  margin-top: 12px;
  border-radius: 15px;
  background-color: #fff;
}
.card-header {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  .shop-icon {
    flex-shrink: 0;
    display: block;
    width: 21px;
    height: 21px;
  }
  .title {
    font-weight: 500;
    font-size: 17px;
    margin-left: 4px;
  }
}

/deep/.operation {
  .van-cell {
    padding: 6px 0;
    .van-field__label {
      font-size: 17px;
      color: #000;
    }
  }
}
// 商品详情
.order-details {
  position: relative;
  padding: 14px 0;
  display: flex;
  justify-content: start;
  font-size: 16px;
  .img-box {
    width: 111px;
    flex-shrink: 0;
    .commodity-img {
      display: block;
      width: 111px;
      height: 111px;
      border-radius: 10px;
    }
  }
  .commodity-info {
    width: 48%;
    margin-left: 10px;
    .title-info {
      margin-bottom: 10px;
      font-size: 17px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .specification {
      font-size: 12px;
      color: #aaaaaa;

      .measure {
        background: #f5f5f5;
        opacity: 1;
        border-radius: 6px;
        padding: 4px 10px;
        text-align: center;
        margin: 10px 0;
        font-size: 14px;
        color: #6f6f6f;
      }
    }
  }
  .commodity-right {
    position: absolute;
    right: 0;
    top: 14px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .beans-num {
      font-size: 17px;
    }
    .number-of {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
}
.tag {
  width: 52px;
  height: 21px;
  border: 1px solid #da432e;
  opacity: 1;
  margin-top: 10px;
  border-radius: 6px;
  text-align: center;
  line-height: 21px;
  color: #da432e;
}
.tag-freight {
  min-width: 52px;
  height: 21px;
  border: 1px solid #da432e;
  opacity: 1;
  margin-top: 10px;
  border-radius: 6px;
  text-align: center;
  line-height: 21px;
  color: #da432e;
}
// 公益豆的价格
.beans-price {
  margin-top: 12px;
  padding: 12px;
  background-color: #fff;
  border-radius: 16px;
  font-size: 17px;
  .price {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
  }
}
// 底部按钮
.bottom-button {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  height: 62px;
  padding: 0 12px;
  background-color: #fff;
  span {
    &:nth-child(3) {
      font-size: 21px;
    }
  }
  .van-button--normal {
    padding: 0 30px;
  }
  .submit-button {
    flex-shrink: 0;
    width: 116px;
    height: 52px;
    font-size: 18px;
    padding: 0;
  }
  .button-box {
    padding-left: 16px;
  }
}
</style>
